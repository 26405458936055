import { type FC } from 'react'

import { type PostResponse } from '../../../../types'
import { type PostRowProps } from '../../types'
import BaseSkeletonRow from '../base/BaseSkeletonRow'
import SentRow from './SentRow'

const isEmptyPublishingPost = (post: PostResponse): boolean =>
  post?.status === 'PUBLISHING' && Object.keys(post).length === 2

const SentRowOrSkeleton: FC<PostRowProps> = ({ post, ...rest }) => {
  if (isEmptyPublishingPost(post)) {
    return <BaseSkeletonRow withCheckbox={false}></BaseSkeletonRow>
  }

  return <SentRow post={post} {...rest} />
}

export default SentRowOrSkeleton
